import React, {useEffect} from 'react';
import {FlatList, Platform, StyleSheet, Text} from 'react-native';
import {View} from '../components/Themed';
import {Button} from "react-native-elements";
import {useSelector, useDispatch} from 'react-redux';
import {heightPercentageToDP as hp, widthPercentageToDP as wp} from 'react-native-responsive-screen';
import {ordersList} from './../containers/actions/app';
import moment from "moment";
import Loading from "../components/Loading";

export default function SearchScreen(props) {
  const dispatch = useDispatch();
  const {orders, user} = useSelector(state => state.app)
  console.log(' orders ... ', orders);
  useEffect(() => {
    dispatch(ordersList(user.uid))
  }, [])
  const OrderRow = ({label, value}) => {
    return (
      <View style={styles.cellContainer}>
        <Text style={styles.cellLabel}>{label}</Text>
        <Text style={styles.cellValue}>{value}</Text>
      </View>
    )
  }
  return (
    <View style={styles.container}>
      <FlatList
        data={orders}
        renderItem={({item}) => {
          return (
            <View style={styles.rowContainer}>
              {/*<OrderRow label={'Invoice # :'} value={item.invoiceId}/>*/}
              <OrderRow label={'Date Time :'} value={moment(item.orderDate).format('DD MMM, yyyy hh:mm a')}/>
              <OrderRow label={'Status :'} value={item.status}/>
              <Button
                onPress={() => {
                  props.navigation.navigate('OrderDetailScreen', {order: item})
                }}
                raised={false}
                titleStyle={{color: '#000'}}
                containerStyle={{
                  width: Platform.OS === 'web' ? '60%' : wp(40),
                  padding: 0,
                  borderRadius: 0,
                  alignSelf: 'flex-end',
                  marginTop: Platform.OS === 'web' ? hp(3) : hp(1),
                }}
                buttonStyle={{backgroundColor: '#ffd56b', borderRadius: 0}}
                title="View Order"/>
            </View>
          )
        }}
        ListEmptyComponent={() => (<Text style={[styles.sectionHeading, {
          marginTop: hp(35),
        }]}>{'No orders'}</Text>)}
      />
      <Loading/>
    </View>
  );
}

const styles = StyleSheet.create({

  rowContainer: {
    width: '100%',
    borderColor: '#a6a9b6',
    borderBottomWidth: 1,
  },
  sectionHeading: {
    fontSize: 16,
    color: '#222831',
    textAlign: 'center',
    padding: 8
  },
  cellLabel: {
    fontSize: 16,
    color: '#23120b',
    textAlign: 'right',
    fontWeight: 'bold',
    width: '35%'
  },

  cellValue: {
    fontSize: 16,
    color: '#23120b',
    textAlign: 'left',
    width: '65%',
    paddingLeft: wp(3)
  },

  cellContainer: {
    width: Platform.OS === 'web' ? wp(70) : wp(85),
    maxWidth: Platform.OS === 'web' ? 600 : 'auto',
    marginTop: hp(2),
    alignItems: 'flex-start',
    flexDirection: 'row',
  },
  container: {
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingTop: 20,
    height: Platform.OS === 'web' ? hp(92) : hp(82),
    paddingBottom: hp(2),
    width: Platform.OS === 'web' ? '100%' : '100%',
    backgroundColor: '#fff'
  },

  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },

  separator: {
    marginVertical: 30,
    height: 1,
    width: '80%',
  },
});
