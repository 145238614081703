import React, {useState} from 'react';
import {Platform, StyleSheet} from 'react-native';
import {Text, View} from '../components/Themed';
import {Button, Input} from "react-native-elements";
import {useDispatch, useSelector} from 'react-redux';
import {heightPercentageToDP as hp, widthPercentageToDP as wp} from 'react-native-responsive-screen';
import moment from "moment";
import Loading from "../components/Loading";
import Wallpapers from "../components/Wallpapers";

export default function ConfirmOrderScreen(props) {
  const dispatch = useDispatch();
  const {user, cart} = useSelector(state => state.app)
  console.log(' order placed ', props);

  return (
    <View style={styles.container}>
      <Wallpapers/>
      <Text style={[styles.cellLabel, styles.inputLabel]}>{'Thank you for your order'}</Text>
      {/*<Text style={[styles.cellLabel, styles.inputLabel]}>{`Order ID: ${props.route.params.orderNumber}`}</Text>*/}
      <View style={styles.rowContainer}>

        <Button
          onPress={() => props.navigation.navigate('Orders')}
          raised
          titleStyle={{color: '#222831'}}
          containerStyle={{marginTop: hp(8),}}
          buttonStyle={{width: wp(60), backgroundColor: '#ffd56b'}}
          title="Go to orders"/>

      </View>
      <Loading/>
    </View>
  );
}

const styles = StyleSheet.create({

  rowContainer: {
    width: '90%',
    borderColor: '#a6a9b6',
    alignItems: 'center'
  },
  sectionHeading: {
    fontSize: 16,
    color: '#222831',
    textAlign: 'center',
    padding: 8
  },
  cellLabel: {
    fontSize: 16,
    color: '#23120b',
  },
  inputLabel: {
    marginTop: hp(1.5)
  },
  cellValue: {
    fontSize: 16,
    color: '#23120b',
    textAlign: 'left',
    width: '70%',
    paddingLeft: wp(3)
  },

  cellContainer: {
    width: wp(85),
    marginTop: hp(4),
    alignItems: 'center',
    flexDirection: 'row'
  },
  inputContainer: {
    marginTop: hp(0),
    marginBottom: hp(0)
  },
  container: {
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingTop: 20,
    height: hp(82),
    paddingBottom: hp(2),
    width: Platform.OS === 'web' ? '100%' : '100%',
    backgroundColor:'#fff'
  },

  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },

  separator: {
    marginVertical: 30,
    height: 1,
    width: '80%',
  },
});
