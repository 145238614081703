import * as React from 'react';
import {Platform, StyleSheet, TextInput} from 'react-native';
import {Text, View} from '../components/Themed';
import {Button, Icon, Input} from "react-native-elements";
import {heightPercentageToDP as hp, widthPercentageToDP as wp} from 'react-native-responsive-screen';
import {useDispatch, useSelector} from 'react-redux';
import Wallpapers from "../components/Wallpapers";
import Loading from "../components/Loading";
import {removeStockItem, updateOrderNotes, updateStockItem} from "../containers/actions/app";
import {KeyboardAwareScrollView} from "react-native-keyboard-aware-scroll-view";

export default function SearchScreen(props) {
  const {cart, user, orderNotes} = useSelector(state => state.app)
  const dispatch = useDispatch();
  return (
    <KeyboardAwareScrollView contentContainerStyle={[styles.container,{minHeight: hp(82)}]}>
      <View style={[styles.container, Platform.OS === 'web' ? {width: '90%'} : {height: 'auto'}]}>
        <Wallpapers/>
        {cart.length > 0 ?

          <>
            <View style={styles.rowContainer}>
              <Text style={[styles.sectionHeading, {width: '35%'}]}>Design no</Text>
              <Text style={[styles.sectionHeading, {width: '35%'}]}>Design name</Text>
              <Text style={[styles.sectionHeading, {width: '30%'}]}>Qty</Text>
            </View>
            {cart.map((item, index) => {
              return (
                <View style={styles.rowContainer} key={item.stock.Code}>
                  <Text style={[styles.productHeading, {width: '35%'}]}>{item.stock.Code}</Text>
                  <Text style={[styles.productHeading, {width: '35%'}]}>{item.stock.Name}</Text>
                  <TextInput
                    keyboardType={'decimal-pad'}
                    value={item.quantity}
                    onChangeText={(text) => {
                      if (text !== '') {
                        const qty = parseFloat(text);
                        if (text.replace(/[^.]/g, "").length > 1 || Number.isNaN(qty) || qty <= 0 || qty > parseFloat(item['Balance Qty'])) {
                          alert('Invalid Quantity')
                          return;
                        }
                        item.quantity = text;
                        dispatch(updateStockItem(item, index))
                      }
                    }}
                    style={[styles.productHeading, {width: '20%'}]}>
                  </TextInput>
                  <Icon
                    onPress={() => {
                      dispatch(removeStockItem(item, index))
                    }}
                    containerStyle={{marginTop: 5, marginLeft: 5}}
                    type={'antdesign'}
                    name='minuscircleo'/>
                </View>
              )
            })}
            <Input
              value={orderNotes}
              onChangeText={(text) => dispatch(updateOrderNotes(text))}
              containerStyle={{
                marginTop: Platform.OS === 'web' ? hp(10) : hp(3),
                width: Platform.OS === 'web' ? '90%' : wp(80)
              }}
              placeholder='Order Notes'
            />
            <Button
              onPress={() => {
                props.navigation.navigate('ConfirmScreen')
              }}
              raised
              containerStyle={{
                marginTop: Platform.OS === 'web' ? hp(5) : hp(2),
                marginBottom: Platform.OS === 'web' ? hp(5) : hp(2),
                width: Platform.OS === 'web' ? '80%' : wp(80),
                maxWidth: Platform.OS === 'web' ? 400 : 'auto'
              }}
              buttonStyle={{backgroundColor: '#f05454'}}
              title="Place Order"/>
          </> :
          <Text style={styles.cellLabel}>{'Cart is empty'}</Text>
        }
        <Button
          onPress={() => props.navigation.navigate('Search')}
          raised
          titleStyle={{color: '#222831'}}
          containerStyle={{
            marginBottom: hp(2),
            width: Platform.OS === 'web' ? '80%' : wp(80),
            maxWidth: Platform.OS === 'web' ? 400 : 'auto',
          }}
          buttonStyle={{backgroundColor: '#ffd56b'}}
          title="Search"/>
        <Loading/>

      </View>
    </KeyboardAwareScrollView>
  );
}

const styles = StyleSheet.create({

  rowContainer: {
    flexDirection: 'row',
    width: Platform.OS === 'web' ? '100%' : wp(90)
  },
  sectionHeading: {
    fontSize: 16,
    color: '#222831',
    backgroundColor: '#cdd0cb',
    borderColor: '#a6a9b6',
    borderWidth: 1,
    textAlign: 'center',
    padding: 8
  },
  productHeading: {
    fontSize: 16,
    color: '#23120b',
    textAlign: 'center',
    padding: 8,
    borderColor: '#a6a9b6',
    borderWidth: 1,
  },

  productContainer: {
    width: wp(85),
    borderWidth: 1,
    marginTop: hp(2),
    padding: 0.2,
    paddingTop: 10,
    borderColor: '#a6a9b6'
  },
  container: {
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: 20,
    backgroundColor: '#fff',
  },

  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  cellLabel: {
    fontSize: 20,
    color: '#23120b',
    textAlign: 'right',
    fontWeight: 'bold',
    marginBottom: hp(5),
    marginTop: hp(7)
  },


});
