import {NavigationContainer, DefaultTheme, DarkTheme} from '@react-navigation/native';
import {createStackNavigator} from '@react-navigation/stack';
import * as React from 'react';
import {ColorSchemeName, Platform} from 'react-native';

import NotFoundScreen from '../screens/NotFoundScreen';
import {RootStackParamList} from '../types';
import BottomTabNavigator from './BottomTabNavigator';
import LinkingConfiguration from './LinkingConfiguration';
import Login from './../screens/login/login';
import ForgotPassword from './../screens/forgotpassword/login';
import Signup from '../screens/signup/signup';
import OrderDetailScreen from "../screens/OrderDetail";
import CartScreen from "../screens/CartScreen";
import ConfirmScreen from "../screens/ConfirmOrderScreen";
import OrderPlacedScreen from "../screens/OrderPlacedScreen";

export default function Navigation({colorScheme}: { colorScheme: ColorSchemeName }) {
  return (
    <NavigationContainer
      linking={LinkingConfiguration}
      theme={DefaultTheme}>
      <RootNavigator/>
    </NavigationContainer>
  );
}

// A root stack navigator is often used for displaying modals on top of all other content
// Read more here: https://reactnavigation.org/docs/modal
const Stack = createStackNavigator<RootStackParamList>();

function RootNavigator() {
  const isWeb = Platform.OS === 'web';
  return (
    <Stack.Navigator
      initialRouteName={'Login'}>
      <Stack.Screen options={{headerShown: false}} name="Root" component={BottomTabNavigator}/>
      <Stack.Screen
        name="Login"
        component={Login}
        options={{
          headerShown: Platform.OS !== 'web',
          headerTitle: 'Login',
          headerStyle: {
            backgroundColor: '#1894da',
          },
          headerTintColor: '#fff',
        }}
      />
      <Stack.Screen
        name="ForgotPassword"
        component={ForgotPassword}
        options={{
          headerShown: Platform.OS !== 'web',
          headerTitle: 'Forgot Password',
          headerStyle: {
            backgroundColor: '#1894da',
          },
          headerTintColor: '#fff',
        }}
      />
      <Stack.Screen
        name="Signup"
        component={Signup}
        options={{
          headerShown: Platform.OS !== 'web',
          headerTitle: 'Signup',
          headerStyle: {
            backgroundColor: '#1894da',
          },
          headerTintColor: '#fff',
        }}
      />
      <Stack.Screen name="NotFound" component={NotFoundScreen} options={{title: 'Oops!'}}/>
      {
        isWeb &&
        <Stack.Screen
          name="OrderDetailScreen"
          component={OrderDetailScreen}
          options={{
            headerTitle: 'Order Detail',
          }}
        />
      }
      {
        isWeb && <Stack.Screen
          name="CartScreen"
          component={CartScreen}
          options={{
            headerTitle: 'Cart',
          }}
        />
      }
      {
        isWeb &&
        <Stack.Screen
          name="ConfirmScreen"
          component={ConfirmScreen}
          options={{
            headerTitle: 'Confirm Order',
          }}
        />
      }
      {
        isWeb &&
        <Stack.Screen
          name="OrderPlacedScreen"
          component={OrderPlacedScreen}
          options={{
            headerTitle: 'Order Placed',
          }}
        />
      }
    </Stack.Navigator>
  );
}
