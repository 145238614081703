export const LOADING = '/app/loading';
export const STOCK_LIST = '/app/stock/list';
export const BANNER_LIST = '/app/banner/list';
export const ADD_TO_CART = '/app/cart/add';
export const ORDER_UPDATED = '/app/order/update';
export const UPDATE_CART_ITEM = '/app/cart/item/update';
export const REMOVE_CART_ITEM = '/app/cart/item/remove';
export const CART_NOTES = '/app/cart/notes';
export const RESET_CART = '/app/cart/reset';
export const LOGIN_SUCCESS = '/app/login/success';
export const LOGIN_FAILED = '/app/login/failed';
export const ORDER_CREATE = '/app/order/create';
export const ORDER_LIST = '/app/order/list';
export const LOGOUT = '/app/logout';
export const SEARCH_COUNT = '/app/search/count';
const defaultState = {
  loading: false,
  stacks: [],
  cart: [],
  orders: [],
  banners: [],
  lastSync: '',
  searches: {}
};
const appReducer = (state = defaultState, action) => {
  switch (action.type) {
    case LOADING:
      return Object.assign({}, state, {
        loading: action.payload.loading,
      });
    case STOCK_LIST:
      return Object.assign({}, state, {
        stocks: action.payload.stocks,
        loading: false,
        lastSync: Date.now()
      });
    case ADD_TO_CART:
      return Object.assign({}, state, {
        cart: [...state.cart, action.payload.item],
        loading: false,
      });
    case CART_NOTES:
      return Object.assign({}, state, {
        orderNotes: action.payload.notes,
        loading: false,
      });
    case UPDATE_CART_ITEM:
      const __cart = [...state.cart];
      __cart[action.payload.index] = action.payload.item;
      return Object.assign({}, state, {
        cart: [...__cart],
        loading: false,
      });
    case REMOVE_CART_ITEM:
      const _cart = [...state.cart];
      _cart.splice(action.payload.index, 1);
      return Object.assign({}, state, {
        cart: [..._cart],
        loading: false,
      });
    case RESET_CART:
      return Object.assign({}, state, {
        cart: [],
        loading: false,
      });
    case ORDER_UPDATED:
      let _orders = [...state.orders]
      _orders = _orders.map(order => order.id === action.payload.order.id ? action.payload.order : order);
      return Object.assign({}, state, {
        orders: _orders,
        loading: false,
      });
    case SEARCH_COUNT:
      console.log(' searches .. ', action.payload.searches)
      return Object.assign({}, state, {
        searches: action.payload.searches,
        loading: false,
      });
    case LOGIN_SUCCESS:
      return Object.assign({}, state, {
        authenticated: true,
        user: action.payload.user,
        loading: false,
      });
    case LOGOUT:
      return Object.assign({}, state, {
        authenticated: false,
        user: {},
        cart: [],
        orders: [],
        loading: false,
      });
    case LOGIN_FAILED:
      return Object.assign({}, state, {
        authenticated: false,
        errorMessage: action.payload.message,
        loading: false,
      });
    case ORDER_LIST:
      return Object.assign({}, state, {
        orders: action.payload.orders,
        loading: false,
      });
    case BANNER_LIST:
      return Object.assign({}, state, {
        banners: action.payload.banners,
        loading: false,
      });
    default:
      return state;
  }
};
export default appReducer;
