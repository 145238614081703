import React from 'react';
import {ActivityIndicator, Platform, StyleSheet, Text, View} from 'react-native';
import {useSelector} from 'react-redux';
import {heightPercentageToDP as hp, widthPercentageToDP as wp} from 'react-native-responsive-screen';
import useColorScheme from "../hooks/useColorScheme";

export default function Loading(props) {
  const isWeb = typeof document != 'undefined';
  const colorScheme = useColorScheme();
  const {loading} = useSelector(state => state.app)
  /*if (isWeb) {
    return (<View/>)
  }*/
  if (!loading)
    return null
  return (
    <View style={[styles.container, props.style ? props.style : {}]}>
      <View style={{backgroundColor: '#cdd0cb', padding: Platform.OS === 'web'? 20 : wp(8), borderRadius: 10}}>
        <ActivityIndicator size="large" color={'#007965'}/>
        <Text>{'Processing...'}</Text>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    width: wp(100),
    height: hp(100),
    position: 'absolute',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: hp(25),
    zIndex: 1000
  },
});
