import * as React from 'react';
import {KeyboardAvoidingView, Platform, SectionList, StyleSheet, TextInput} from 'react-native';
import {Text, View} from '../components/Themed';
import {Button, Input, Overlay} from "react-native-elements";
import {heightPercentageToDP as hp, widthPercentageToDP as wp} from 'react-native-responsive-screen';
import {useDispatch, useSelector} from 'react-redux';
import Wallpapers from "../components/Wallpapers";
import Loading from "../components/Loading";
import {updateOrderNotes, updateStockItem} from "../containers/actions/app";
import Modal from 'modal-react-native-web';
import moment from "moment";
import {useEffect, useState} from "react";

export default function OrderDetailScreen(props) {
  const {user} = useSelector(state => state.app)
  const [showTracking, setShowTracking] = useState(false);
  const order = props.route.params.order;
  const dispatch = useDispatch();
  const OrderRow = ({label, value}) => {
    return (
      <View style={styles.cellContainer}>
        <Text style={styles.cellLabel}>{label}</Text>
        <Text style={styles.cellValue}>{value}</Text>
      </View>
    )
  }
  const TrackingRow = ({label, value}) => {
    return (
      <View style={[styles.cellContainer, {marginTop: hp(2)}]}>
        <Text style={[styles.cellLabel, {fontSize: 18}]}>{label}</Text>
        <Text style={[styles.cellValue, {fontSize: 18}]}>{value}</Text>
      </View>
    )
  }
  return (
    <KeyboardAvoidingView style={styles.container} behavior={Platform.OS === "ios" ? "padding" : "height"}>
      <View style={[styles.container, {padding: 0, width: Platform.OS === 'web' ? '80%' : 'auto'}]}>
        <View style={[styles.detailRowContainer, {marginTop: hp(5)}]}>
          {/*<OrderRow label={'Invoice Id'} value={order.invoiceId}/>*/}
          <OrderRow label={'Order Date'} value={moment(order.timestamp).format('DD MMM, yyyy hh:mm a')}/>
          <OrderRow label={''} value={''}/>
        </View>
        <View style={styles.detailRowContainer}>
          <OrderRow label={'Name'} value={user.name}/>
          <OrderRow label={'Address'} value={order.address}/>
        </View>
        <View style={styles.detailRowContainer}>
          <OrderRow label={'Courier Mode'} value={order.courierMode}/>
          <OrderRow label={'Courier Name'} value={order.courierName}/>
        </View>
        <View style={[styles.detailRowContainer]}>
          <OrderRow label={'Status'} value={order.status}/>
          <View style={styles.cellContainer}>
            <Text style={styles.cellLabel}>{'Tracking'}</Text>
            {order.tracking &&
            <Button
              onPress={() => setShowTracking(true)}
              containerStyle={{marginTop: hp(1)}}
              title={'View tracking'}/>
            }
          </View>
        </View>
        <Text style={[styles.stockHeading]}>{'Stocks Detail'}</Text>
        <SectionList
          keyExtractor={(item) => item.Code}
          style={{
            maxHeight: (hp(4.1) * (order.stocks.length + 1)),
            width: Platform.OS === 'web' ? '100%' : 'auto'
          }}
          renderSectionHeader={({section: {title}}) => (
            <View style={styles.rowContainer}>
              <Text style={[styles.sectionHeading, {width: '35%'}]}>Design no</Text>
              <Text style={[styles.sectionHeading, {width: '40%'}]}>Design name</Text>
              <Text style={[styles.sectionHeading, {width: '25%'}]}>Qty</Text>
            </View>
          )}
          renderItem={({item, index}) => {
            return (
              <View style={styles.rowContainer}>
                <Text style={[styles.productHeading, {width: '35%'}]}>{item.stock.Code}</Text>
                <Text style={[styles.productHeading, {width: '40%'}]}>{item.stock.Name}</Text>
                <TextInput
                  value={item.quantity}
                  keyboardType={'decimal-pad'}
                  /*onChangeText={(text) => {
                    item.quantity = text;
                    dispatch(updateStockItem(item, index))
                  }}*/
                  editable={false}
                  style={[styles.productHeading, {width: '25%'}]}>
                </TextInput>
              </View>
            )
          }}
          sections={[{title: '', data: order.stocks}]}
        />
        {showTracking &&
        <Overlay
          ModalComponent={Modal}
          isVisible={showTracking} onBackdropPress={() => {
          setShowTracking(false)
        }}>
          {order.tracking &&
          <View style={Platform.OS === 'web' ? {width: '100%', minWidth: 300} : {width: wp(80)}}>
            <Text style={{fontWeight: 'bold', fontSize: 22, marginBottom: 20}}>Tracking Info</Text>
            <TrackingRow label={'Order Number'} value={order.tracking.orderNumber}/>
            <TrackingRow label={'Courier Mode'} value={order.tracking.mode}/>
            <TrackingRow label={'Courier Name'} value={order.tracking.name}/>
            <TrackingRow label={'Tracking Id'} value={order.tracking.trackingId}/>
            <Button
              onPress={() => setShowTracking(false)}
              containerStyle={{marginTop: hp(3)}}
              title={'Close'}/>
          </View>}
        </Overlay>
        }
        <Loading/>

      </View>
    </KeyboardAvoidingView>
  );
}

const styles = StyleSheet.create({

  rowContainer: {
    flexDirection: 'row',
    width: Platform.OS === 'web' ? '100%' : wp(90)
  },
  sectionHeading: {
    fontSize: 16,
    color: '#222831',
    backgroundColor: '#cdd0cb',
    borderColor: '#a6a9b6',
    borderWidth: 1,
    textAlign: 'center',
    padding: 8
  },
  productHeading: {
    fontSize: 16,
    color: '#23120b',
    textAlign: 'center',
    padding: 8,
    borderColor: '#a6a9b6',
    borderWidth: 1,
  },

  productContainer: {
    width: wp(85),
    borderWidth: 1,
    marginTop: hp(2),
    padding: 0.2,
    paddingTop: 10,
    borderColor: '#a6a9b6'
  },
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: 20,
    backgroundColor: '#fff'
  },

  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  cellLabel: {
    fontSize: 20,
    color: '#23120b',
    textAlign: 'right',
    fontWeight: 'bold',
    marginBottom: hp(5),
    marginTop: hp(7)
  },
  stockHeading: {
    fontSize: 20,
    color: '#23120b',
    textAlign: 'left',
    fontWeight: 'bold',
    marginBottom: hp(1),
    marginTop: hp(5),
    marginLeft: wp(5),
    alignSelf: 'flex-start'
  },
  cellLabel: {
    fontSize: 14,
    color: '#23120b',
    textAlign: 'left',
    fontWeight: 'bold',
    width: '100%'
  },
  inputLabel: {
    marginTop: hp(1.5)
  },
  cellValue: {
    fontSize: 14,
    color: '#23120b',
    textAlign: 'left',
    width: '100%',
  },
  cellContainer: {
    width: Platform.OS === 'web' ? '45%' : wp(45),
    marginTop: hp(1),
    alignItems: 'flex-start',
    flexDirection: 'column',
    marginLeft: wp(5)
  },
  detailRowContainer: {
    flexDirection: 'row',
    width: Platform.OS === 'web' ? '100%' : 'auto'
  }
});
