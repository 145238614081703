import React, {useState} from "react";
import {Platform} from "react-native";
import styles from "./style";
import {
  Keyboard,
  Text,
  View,
  TextInput,
  TouchableWithoutFeedback,
  Alert,
  KeyboardAvoidingView,
  ActivityIndicator
} from 'react-native';
import {Button} from 'react-native-elements';
import Wallpapers from "../../components/Wallpapers";
import {useDispatch, useSelector} from "react-redux";
import {createAccount, emailLogin} from "../../containers/actions/app";
import {widthPercentageToDP as wp, heightPercentageToDP as hp} from 'react-native-responsive-screen';
import Loading from "../../components/Loading";

function LoginScreen(props) {
  const dispatch = useDispatch();
  const [name, setName] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [mobile, setMobile] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const {authenticated, user, errorMessage} = useSelector(state => state.app)
  const isWeb = Platform.OS === 'web';
  const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }
  const validatePhoneNumber = (text) => {
    const phoneNo = /^\d{10}$/;
    return !!text.match(phoneNo);
  }
  const onSignupPress = () => {
    if (!validateEmail(email)) {
      alert('Enter a valid email address');
      return;
    }
    if (password.length !== 6) {
      alert('Enter a 6 digit pin');
      return;
    }
    if (companyName.length === 0) {
      alert('Enter a company name');
      return;
    }
    if (name.length === 0) {
      alert('Enter a name');
      return;
    }
    if (!validatePhoneNumber(mobile)) {
      alert('Enter a valid mobile number');
      return;
    }
    dispatch(createAccount({
      email: email.toLowerCase(),
      mobile,
      name,
      password,
      companyName,
      type: 'dealer',
      status:'inactive',
      permission: 'stock'
    }))
      .then(() => {
        props.navigation.replace('Login')
        alert('You have registered successfully. Please wait for a moderator to activate your account.')
      }, (error) => {
        alert(error)
      })
  }
  return (
    <KeyboardAvoidingView style={styles.containerView} behavior="padding">
      <TouchableWithoutFeedback >
        <View style={styles.loginScreenContainer}>
          <View style={styles.loginFormView}>
            <Text style={styles.logoText}>Create New Account</Text>
            <TextInput
              value={name}
              onChangeText={(text) => setName(text)}
              placeholder="Name"
              placeholderColor="#c4c3cb"
              style={styles.loginFormTextInput}/>
            <TextInput
              onChangeText={(text) => setCompanyName(text)}
              placeholder="Company name"
              placeholderColor="#c4c3cb"
              style={styles.loginFormTextInput}/>
            <TextInput
              onChangeText={(text) => setEmail(text)}
              keyboardType={'email-address'}
              placeholder="Email"
              placeholderColor="#c4c3cb"
              style={styles.loginFormTextInput}/>
            <TextInput
              onChangeText={(text) => setMobile(text)}
              keyboardType={'number-pad'}
              placeholder="Mobile"
              placeholderColor="#c4c3cb"
              style={styles.loginFormTextInput}/>
            <TextInput
              onChangeText={(text) => setPassword(text)}
              keyboardType={'number-pad'}
              placeholder="Enter 6 digit Pin"
              placeholderColor="#c4c3cb"
              style={styles.loginFormTextInput}
              secureTextEntry={true}/>
            <Button
              buttonStyle={{backgroundColor:'#f05454'}}
              containerStyle={styles.loginButton}
              onPress={() => onSignupPress()}
              title="Create account"
            />
            <Text style={{marginTop: hp(4)}}>Already have an account?</Text>
            <Button
              onPress={() => props.navigation.replace('Login')}
              titleStyle={{color: '#222831'}}
              containerStyle={[styles.loginButton,{marginTop: hp(2),backgroundColor: '#ffd56b'}]}
              buttonStyle={{ backgroundColor: '#ffd56b'}}
              title="Login"/>
          </View>
          <Loading/>
        </View>
      </TouchableWithoutFeedback>
    </KeyboardAvoidingView>
  );
}

export default LoginScreen;
