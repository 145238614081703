import React, {useState} from "react";

import styles from "./style";
import {
  Keyboard,
  Text,
  View,
  TextInput,
  TouchableWithoutFeedback,
  Alert,
  KeyboardAvoidingView,
  ActivityIndicator
} from 'react-native';
import {Button} from 'react-native-elements';
import Wallpapers from "../../components/Wallpapers";
import {useDispatch, useSelector} from "react-redux";
import {createAccount, emailLogin, logout} from "../../containers/actions/app";
import Loading from "../../components/Loading";

function ProfileScreen(props) {
  const dispatch = useDispatch();
  const {authenticated, user} = useSelector(state => state.app)
  if (!authenticated){
    props.navigation.replace('Login')
  }
  const onLogoutPress = () => {
    dispatch(logout())
      .then(() => {
      }, (error) => {
        alert(error)
      })
  }
  return (
    <KeyboardAvoidingView style={styles.containerView} behavior="padding">
      <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
        <View style={styles.loginScreenContainer}>
          <View style={styles.loginFormView}>
            <Text style={styles.logoText}>Profile</Text>
            <TextInput
              value={user.name}
              editable={false}
              style={styles.loginFormTextInput}/>
            <TextInput
              editable={false}
              value={user.companyName}
              style={styles.loginFormTextInput}/>
            <TextInput
              editable={false}
              value={user.email}
              style={styles.loginFormTextInput}/>
            <TextInput
              editable={false}
              value={user.mobile}
              style={styles.loginFormTextInput}/>
            <Button
              buttonStyle={{backgroundColor:'#e47729'}}
              containerStyle={styles.loginButton}
              onPress={() => onLogoutPress()}
              title="Logout"
            />
          </View>
          <Loading/>
        </View>
      </TouchableWithoutFeedback>
    </KeyboardAvoidingView>
  );
}

export default ProfileScreen;
