import React, {useEffect, useState} from 'react';
import {Platform, StyleSheet, KeyboardAvoidingView, FlatList, TextInput} from 'react-native';
import {useSelector, useDispatch} from 'react-redux';
import EditScreenInfo from '../components/EditScreenInfo';
import {Text, View} from '../components/Themed';
import {Button, Card, Input} from "react-native-elements";
import {widthPercentageToDP as wp, heightPercentageToDP as hp} from 'react-native-responsive-screen';
import {KeyboardAwareScrollView} from "react-native-keyboard-aware-scroll-view";
import Wallpapers from "../components/Wallpapers";
import {stockList, addToStock, updateSearchCount} from './../containers/actions/app';
import Loading from "../components/Loading";
import moment from "moment";


export default function SearchScreen(props) {
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState('');
  const [results, setResults] = useState([]);
  const {stocks, lastSync, user, searches} = useSelector(state => state.app)
  useEffect(() => {
    dispatch(stockList(lastSync))
  }, [])
  const onProductSearch = (e) => {
    console.log('searches ', moment().format('DDMMYYYY'))
    const limit = user.searchCount ? Number.parseInt(user.searchCount) : 100;
    const count = searches && searches[moment().format('DDMMYYYY')] ? searches[moment().format('DDMMYYYY')] : 0;
    if (count >= limit) {
      alert('Something is wrong with your account. Please contact admin.')
      return;
    }
    const term = searchTerm;
    console.log('term ', term)
    const avaStock = [];
    console.log(' stocks ', stocks.length)
    if (stocks && stocks.length > 0) {
      stocks.forEach((stock) => {
        if (`${stock.Code}`.toLowerCase() === `${term}`.toLowerCase()) {
          avaStock.push({...stock, quantity: '1'})
        }
      })
    }
    if (term.length > 0 && avaStock.length === 0) {
      alert('No product found')
    }
    setResults(avaStock.slice(0, 1));
    dispatch(updateSearchCount(searches))
  }
  return (
    <KeyboardAwareScrollView
      keyboardShouldPersistTaps='handled'
      contentContainerStyle={styles.container}>
      <View style={styles.topContainer}>
        <Wallpapers/>
        <Input
          value={searchTerm}
          onChangeText={(value) => setSearchTerm(value)}
          placeholder='Enter Design no'
          onBlur={Platform.OS === 'web' ? () => {
          } : onProductSearch}
        />
        <Button
          onPress={onProductSearch}
          raised={false}
          useForeground
          containerStyle={{marginBottom: hp(1), width: '70%', marginTop: Platform.OS === 'web' ? '10%' : 0}}
          buttonStyle={{backgroundColor: '#e47729'}}
          title="Search"/>
      </View>
      <FlatList
        contentContainerStyle={{width: wp(90), alignItems:'center'}}
        data={results}
        renderItem={({item, index}) => {
          return (
            <View style={styles.productContainer}>
              <Text style={styles.productHeading}>Design no : {<Text
                style={{fontWeight: 'bold'}}>{item.Code}</Text>}</Text>
              <Text style={styles.productHeading}>{`Design name : ${item.Name}`}</Text>
              <Text style={styles.productHeading}>Qty : {<Text style={{color: '#16c79a'}}>
                {item['Balance Qty'] > 10 ? `10+ available` : `${item['Balance Qty']} available`}
              </Text>}</Text>
              {item['Balance Qty'] < 10 &&
              <Text style={styles.productHeading}>
                <Text style={{color: '#ef4f4f'}}>
                  (Kindly check before ordering)
                </Text>
              </Text>
              }
              {user.permission === 'order' &&
              <>
                <Input
                  containerStyle={{marginTop: hp(2)}}
                  value={item.quantity}
                  onChangeText={(value) => {
                    const _results = [...results];
                    _results[index] = {...item, quantity: value}
                    setResults(_results);
                  }}
                  placeholder='Enter Qty'
                  keyboardType={'numeric'}
                />
                <Button
                  useForeground={true}
                  onPress={() => {
                    const qty = parseFloat(item.quantity);
                    if (!item.quantity || item.quantity.replace(/[^.]/g, "").length > 1 || Number.isNaN(qty) || qty <= 0 || qty > parseFloat(item['Balance Qty'])) {
                      alert('Invalid Quantity')
                      return;
                    }
                    setSearchTerm('');
                    setResults([])
                    dispatch(addToStock({stock: item, quantity: item.quantity},
                      (success) => {
                        alert(success);
                      }))
                  }}
                  raised={false}
                  containerStyle={{
                    width: '50%',
                    padding: 0,
                    borderRadius: 0,
                    marginTop: hp(0),
                    alignSelf: 'center',
                    marginBottom: hp(2)
                  }}
                  buttonStyle={{backgroundColor: '#f05454'}}
                  title="add to cart"/>
              </>
              }
            </View>
          )
        }}
      />
      {user.permission === 'order' &&
      <Button
        onPress={() => props.navigation.navigate('Cart')}
        raised={false}
        titleStyle={{color: '#222831'}}
        containerStyle={{
          marginBottom: hp(4),
          marginTop: hp(5),
          width: '70%',
          maxWidth: Platform.OS === 'web' ? 350 : 'auto'
        }}
        buttonStyle={{backgroundColor: '#ffd56b'}}
        title="View Cart"/>
      }
      <Loading/>

    </KeyboardAwareScrollView>
  );
}

const styles = StyleSheet.create({
  galleryContainer: {
    width: wp(90),
    height: hp(13),
    marginBottom: hp(5)
  },
  productContainer: {
    width: '95%',
    borderWidth: 1,
    marginTop: hp(2),
    padding: 0.2,
    paddingTop: 10,
    borderColor: '#a6a9b6',
    minWidth: Platform.OS === 'web' ? 300 : 'auto',
    maxWidth: Platform.OS === 'web' ? 400 : 'auto'
  },
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: 20,
    minHeight: Platform.OS === 'web' ? hp(95) : 0,
    backgroundColor: '#fff'
  },
  topContainer: {
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    maxWidth: Platform.OS === 'web' ? 500 : 'auto'
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  productHeading: {
    fontSize: 16,
    color: '#3b5360',
    marginLeft: 10
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: '80%',
  },
});
