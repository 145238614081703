import React, {useState} from "react";
import {heightPercentageToDP as hp} from 'react-native-responsive-screen';
import styles from "./style";
import {Keyboard, KeyboardAvoidingView, TextInput, TouchableWithoutFeedback, View} from 'react-native';
import {Button} from 'react-native-elements';
import Wallpapers from "../../components/Wallpapers";
import {useDispatch, useSelector} from "react-redux";
import {emailLogin} from "../../containers/actions/app";
import Loading from "../../components/Loading";
import {firebase} from "../../firebase";

function ForgotPassword(props) {
  console.log(props)
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const {authenticated, user, errorMessage} = useSelector(state => state.app)
  if (authenticated) {
    props.navigation.replace('Root')
  }
  const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }
  const validatePhoneNumber = (text) => {
    const phoneNo = /^\d{10}$/;
    return !!text.match(phoneNo);
  }
  const onLoginPress = () => {
    if (!validateEmail(email) && !validatePhoneNumber(email)) {
      alert('Enter a valid email address or phone number');
      return;
    }
    firebase.auth().sendPasswordResetEmail(email.toLowerCase()).then(function() {
      alert('We have sent password reset email. Please check you inbox');
    }).catch(function(error) {
      console.log(error)
      alert('Invalid email address');
    });
  }
  return (
    <KeyboardAvoidingView style={styles.containerView} behavior="padding">
      <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
        <View style={styles.loginScreenContainer}>
          <View style={styles.loginFormView}>
            <Wallpapers/>
            <TextInput
              onChangeText={(text) => setEmail(text)}
              keyboardType={'email-address'}
              placeholder="Email" placeholderColor="#c4c3cb"
              style={[styles.loginFormTextInput, {marginTop: hp(5)}]}/>

            <Button
              buttonStyle={{backgroundColor: '#f05454'}}
              containerStyle={styles.loginButton}
              onPress={() => onLoginPress()}
              title="Reset Password"
            />

          </View>
          <Loading/>
        </View>
      </TouchableWithoutFeedback>
    </KeyboardAvoidingView>
  );
}

export default ForgotPassword;
