import React, {useState} from 'react';
import {Platform, StyleSheet} from 'react-native';
import {Text, View} from '../components/Themed';
import {Button, Input} from "react-native-elements";
import {useDispatch, useSelector} from 'react-redux';
import {heightPercentageToDP as hp, widthPercentageToDP as wp} from 'react-native-responsive-screen';
import moment from "moment";
import Loading from "../components/Loading";
import Wallpapers from "../components/Wallpapers";
import {placeOrder} from "../containers/actions/app";

export default function ConfirmOrderScreen(props) {
  const dispatch = useDispatch();
  const {user, cart} = useSelector(state => state.app)
  const [address, setAddress] = useState('');
  const [courierMode, setCourierMode] = useState('');
  const [courierName, setCourierName] = useState('');

  const submitOrder = () => {
    if (!address) {
      alert('Please enter a valid address');
      return;
    }
    if (!courierMode) {
      alert('Please enter courier mode');
      return;
    }
    if (!courierName) {
      alert('Please enter courier name');
      return;
    }
    let total = 0;
    cart.forEach(stock => {
      total += parseFloat(stock.quantity);
    })
    const order = {
      uid: user.uid,
      timestamp: Date.now(),
      courierName,
      courierMode,
      address,
      status: 'new',
      invoiceId: moment().format('yyyy-MM-DD-') + Math.floor(1000 + Math.random() * 9000),
      stocks: cart,
      name: user.name,
      quantity: total
    }
    dispatch(placeOrder(order, user)).then((success) => {
      props.navigation.replace('OrderPlacedScreen', {orderNumber: order.orderNumber})
    }, (error) => {
      alert(error)
    })
    props.navigation.navigate('ConfirmScreen')
  }

  const OrderRow = ({label, value}) => {
    return (
      <View style={styles.cellContainer}>
        <Text style={styles.cellLabel}>{label}</Text>
        <Text style={styles.cellValue}>{value}</Text>
      </View>
    )
  }
  return (
    <View style={styles.container}>
      <Wallpapers/>
      <View style={styles.rowContainer}>
        <OrderRow label={'Name '} value={user.name}/>
        <OrderRow label={'Date '} value={moment().format('DD MMM, yyyy hh:mm a')}/>
        <View style={[styles.cellContainer, {marginTop: hp(2)}]}>
          <Text style={[styles.cellLabel, styles.inputLabel]}>{'Address'}</Text>
          <Input containerStyle={{width: Platform.OS === 'web' ? '50%' : wp(60)}}
                 onChangeText={(text) => setAddress(text)}/>
        </View>
        <View style={[styles.cellContainer, styles.inputContainer]}>
          <Text style={[styles.cellLabel, styles.inputLabel]}>{'Courier Mode'}</Text>
          <Input containerStyle={{width: Platform.OS === 'web' ? '50%' : wp(60)}}
                 onChangeText={(text) => setCourierMode(text)}/>
        </View>
        <View style={[styles.cellContainer, styles.inputContainer]}>
          <Text style={[styles.cellLabel, styles.inputLabel]}>{'Courier Name'}</Text>
          <Input containerStyle={{width: Platform.OS === 'web' ? '50%' : wp(60)}} onChangeText={(text) => {
            setCourierName(text)
          }}/>
        </View>
        <Button
          onPress={() => {
            submitOrder()
          }}
          raised
          containerStyle={{
            marginTop: hp(4),
            width: Platform.OS === 'web' ? '80%' : wp(60),
            maxWidth: Platform.OS === 'web' ? 400 : 'auto',
          }}
          buttonStyle={{backgroundColor: '#f05454'}}
          title="Place Order"/>
        <Button
          onPress={() => props.navigation.navigate('SearchScreen')}
          raised
          titleStyle={{color: '#222831'}}
          containerStyle={{marginTop: hp(2), width: Platform.OS === 'web' ? '80%' : wp(60)}}
          buttonStyle={{backgroundColor: '#ffd56b'}}
          title="Search"/>
      </View>
      <Loading/>
    </View>
  );
}

const styles = StyleSheet.create({

  rowContainer: {
    width: '90%',
    borderColor: '#a6a9b6',
    alignItems: 'center'
  },
  sectionHeading: {
    fontSize: 16,
    color: '#222831',
    textAlign: 'center',
    padding: 8
  },
  cellLabel: {
    fontSize: 16,
    color: '#23120b',
    textAlign: 'left',
    fontWeight: 'bold',
    width: Platform.OS === 'web' ? '50%' : '30%'
  },
  inputLabel: {
    marginTop: hp(1.5)
  },
  cellValue: {
    fontSize: 16,
    color: '#23120b',
    textAlign: 'left',
    width: Platform.OS === 'web' ? '50%' : '70%',
    paddingLeft: wp(3)
  },

  cellContainer: {
    width: Platform.OS === 'web' ? '100%' : wp(85),
    marginTop: hp(4),
    alignItems: 'flex-start',
    flexDirection: 'row'
  },
  inputContainer: {
    marginTop: hp(0),
    marginBottom: hp(0)
  },
  container: {
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingTop: 20,
    height: Platform.OS === 'web' ? hp(90) : hp(82),
    paddingBottom: hp(2),
    width: Platform.OS === 'web' ? '100%' : '100%',
    backgroundColor: '#fff'
  },

  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },

  separator: {
    marginVertical: 30,
    height: 1,
    width: '80%',
  },
});
