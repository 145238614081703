import {
  ADD_TO_CART,
  BANNER_LIST,
  CART_NOTES,
  LOADING,
  LOGIN_FAILED,
  LOGIN_SUCCESS,
  LOGOUT,
  ORDER_LIST,
  ORDER_UPDATED,
  REMOVE_CART_ITEM,
  RESET_CART,
  SEARCH_COUNT,
  STOCK_LIST,
  UPDATE_CART_ITEM
} from '../reducers/app';
import {firebase, firestore} from "../../firebase";
import * as Device from 'expo-device';
import * as Network from 'expo-network';
import moment from "moment";
import NetInfo from '@react-native-community/netinfo';
export const app = (email, password) => {
  return async (dispatch) => {
    dispatch({
      type: LOADING,
      payload: {loading: true},
    });

    const params = {
      username: email,
      password: password,
    };
    const response = await fetch(`https://dev.auth.skeduler.com.au/api/access/tech-login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(params),
    });
    const responseJson = await response.json();
    if (responseJson.access_token) {
      dispatch({
        type: 'LOGIN',
        payload: {
          loading: false,
          auth: responseJson,
          loginSuccess: true,
        },
      });
      return Promise.resolve(responseJson);
    }
    dispatch({
      type: LOADING,
      payload: {loading: false},
    });
    return Promise.reject(responseJson.message);
  };
};

export const ordersList = (uid) => {
  return async (dispatch) => {
    dispatch({
      type: LOADING,
      payload: {loading: true},
    });
    const snapshot = await firestore.collection('order')
      .where("uid", "==", uid)
      .orderBy("timestamp", "desc")
      .get()
    const docs = snapshot.docs.map(doc => ({id: doc.id, ...doc.data()}));
    dispatch({
      type: ORDER_LIST,
      payload: {orders: docs},
    });
  };
};

export const bannersList = (uid) => {
  return async (dispatch) => {
    dispatch({
      type: LOADING,
      payload: {loading: true},
    });
    const snapshot = await firestore.collection('banner').get()
    const docs = snapshot.docs.map(doc => doc.data());
    dispatch({
      type: BANNER_LIST,
      payload: {banners: docs},
    });
  };
};

export const stockList = (lastSync) => {
  return async (dispatch) => {
    dispatch({
      type: LOADING,
      payload: {loading: true},
    });
    try {
      const metaSnapshot = await firestore.collection('metadata').doc('sync').get();
      const metadata = metaSnapshot.data();
      console.log(' metadata ', metadata['uploadDate'], lastSync);
      if (!lastSync || metadata['uploadDate'] > lastSync) {

        const snapshot = await firestore.collection('metadata').doc('data').collection('stocks').get();
        let stocks = []
        snapshot.docs.forEach(doc => {
          stocks = [...doc.data().stocks, ...stocks]
        });
        console.log(' sync performed ');
        dispatch({
          type: STOCK_LIST,
          payload: {stocks: stocks},
        });

      } else {
        console.log(' sync not performed ');
        dispatch({
          type: LOADING,
          payload: {loading: false},
        });
      }
    } catch (e) {
      dispatch({
        type: LOADING,
        payload: {loading: false},
      });
    }
  };
};

export const addToStock = (item, callback) => {
  return async (dispatch) => {
    dispatch({
      type: LOADING,
      payload: {loading: true},
    });
    setTimeout(() => {
      dispatch({
        type: ADD_TO_CART,
        payload: {item},
      });
      callback('Item added to cart')
    }, 1500)
  };
};
export const updateStockItem = (item, index) => {
  return async (dispatch) => {
    dispatch({
      type: LOADING,
      payload: {loading: true},
    });
    dispatch({
      type: UPDATE_CART_ITEM,
      payload: {item, index},
    });
  };
};
export const removeStockItem = (item, index) => {
  return async (dispatch) => {
    dispatch({
      type: LOADING,
      payload: {loading: true},
    });
    dispatch({
      type: REMOVE_CART_ITEM,
      payload: {item, index},
    });
  };
};
export const updateOrderNotes = (notes) => {
  return async (dispatch) => {
    dispatch({
      type: LOADING,
      payload: {loading: true},
    });
    dispatch({
      type: CART_NOTES,
      payload: {notes},
    });
  };
};
export const sendUserEmail = (to, subject, title, body, order) => {
  return async (dispatch) => {
    try {
      let res = await fetch('https://us-central1-wallking-hosting.cloudfunctions.net/sendOrderEmail', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          to,
          subject,
          title,
          bodytext: body,
          order
        }),
      });
      res = await res.json();
    } catch (e) {
      console.error(e);
    }
  };
};
export const placeOrder = (order, user) => {
  return async (dispatch) => {
    dispatch({
      type: LOADING,
      payload: {loading: true},
    });
    try {
      order['history'] = [];
      order['history'].push({
        timestamp: Date.now(),
        event: `Order Created`,
        by: `${user.name}, ${user.mobile}, ${user.email}`
      })
      const result = await firestore.collection('order').add(order);
      dispatch(sendUserEmail(user.email, 'Order Placed', 'New Order', 'This is to confirm you that we have received your order.', order))
      dispatch(sendUserEmail('admin', 'New Order Placed', 'New Order', `user ${user.name} has placed a new order.`, order))
      dispatch({
        type: RESET_CART,
        payload: {},
      });
      dispatch(ordersList(order.uid));
      return Promise.resolve('Order submitted successfully')
    } catch (e) {
      dispatch({
        type: LOADING,
        payload: {loading: false},
      });
      return Promise.reject(e)
    }
  };
};
export const updateSearchCount = (searches) => {
  return async (dispatch) => {
    try {
      searches = searches ? searches : {};
      const count = searches[moment().format('DDMMYYYY')] ? searches[moment().format('DDMMYYYY')] : 0;
      searches[`${moment().format('DDMMYYYY')}`] = count + 1;
      dispatch({
        type: SEARCH_COUNT,
        payload: {searches},
      })
    } catch (e) {
      console.log(e)
    }
  }
}
export const listenForUpdates = (email, lastSync) => {
  return async (dispatch) => {
    try {
      const mobSnapshot = await firestore.collection('user').where('email', '==', email).limit(1).get()
      if (mobSnapshot.docs && mobSnapshot.docs.length > 0) {
        const data = await mobSnapshot.docs[0].data();
        firestore.collection('user').doc(mobSnapshot.docs[0].id)
          .onSnapshot((doc) => {
            const source = doc.metadata.hasPendingWrites ? "Local" : "Server";
            if (source === 'Server') {
              console.log('updates from server ');
              dispatch({
                type: LOGIN_SUCCESS,
                payload: {user: {id: doc.id, ...doc.data()}},
              });
            }
          });
        firestore.collection("order")
          .where("uid", "==", data.uid)
          .onSnapshot(function (snapshot) {
            snapshot.docChanges().forEach(function (change) {
              if (change.type === "modified") {
                const source = change.doc.metadata.hasPendingWrites ? "Local" : "Server";
                if (source === 'Server') {
                  console.log('updates from server ');
                  dispatch({
                    type: ORDER_UPDATED,
                    payload: {order: {id: change.doc.id, ...change.doc.data()}},
                  });
                }
              }
            });
          });

        firestore.collection('metadata').doc('sync')
          .onSnapshot(async (doc) => {
            const source = doc.metadata.hasPendingWrites ? "Local" : "Server";
            if (source === 'Server') {
              console.log('updates from server ');
              const metadata = doc.data();
              if (!lastSync || metadata['uploadDate'] > lastSync) {
                const snapshot = await firestore.collection('metadata').doc('data').collection('stocks').get();
                let stocks = []
                snapshot.docs.forEach(doc => {
                  stocks = [...doc.data().stocks, ...stocks]
                });
                console.log(' sync performed ');
                dispatch({
                  type: STOCK_LIST,
                  payload: {stocks: stocks},
                });
              }
            }
          });

        firestore.collection("banner")
          .onSnapshot(function (snapshot) {
            snapshot.docChanges().forEach(async function (change) {
              const source = change.doc.metadata.hasPendingWrites ? "Local" : "Server";
              console.log(' banner changes from server')
              if (source === 'Server') {
                const snapshot = await firestore.collection('banner').get()
                const docs = snapshot.docs.map(doc => doc.data());
                dispatch({
                  type: BANNER_LIST,
                  payload: {banners: docs},
                });
              }

            });
          });
      }
    } catch (e) {

    }
  }
}
export const fetchUser = (email) => {
  return async (dispatch) => {
    try {
      const ip = await Network.getIpAddressAsync()
      const mobSnapshot = await firestore.collection('user').where('email', '==', email).limit(1).get()
      if (mobSnapshot.docs && mobSnapshot.docs.length > 0) {
        const data = await mobSnapshot.docs[0].data();
        data['id'] = mobSnapshot.docs[0].id;
        const loginHistory = data['loginHistory'] ? data['loginHistory'] : [];
        const history = loginHistory.slice(0, 48);
        const current = {device: `${Device.brand} ${Device.modelName} ${Device.deviceName}`, ip, timestamp: Date.now()}
        data['loginHistory'] = [current, ...history]
        await firestore.collection('user').doc(mobSnapshot.docs[0].id).set(data)
        if (data.status === 'active') {
          dispatch({
            type: LOGIN_SUCCESS,
            payload: {user: data},
          });
        } else {
          dispatch({
            type: LOGOUT,
            payload: {},
          });
        }
      } else {
        const networkState = await NetInfo.fetch();
        if(networkState.isConnected) {
          dispatch({
            type: LOGOUT,
            payload: {},
          });
        }
      }
    } catch (e) {
      console.log(e)
    }
  }
}
export const emailLogin = (email, password) => {
  return async (dispatch) => {
    dispatch({
      type: LOADING,
      payload: {loading: true},
    });
    try {
      if (email.length === 10) {
        const mobSnapshot = await firestore.collection('user').where('mobile', '==', email).limit(1).get()
        if (mobSnapshot.docs && mobSnapshot.docs.length > 0) {
          const data = await mobSnapshot.docs[0].data();
          data['id'] = mobSnapshot.docs[0].id;
          email = data.email
        } else {
          dispatch({
            type: LOGIN_FAILED,
            payload: {message: ''},
          });
          return Promise.reject('wrong credentials')
        }
      }
      const results = await firebase.auth().signInWithEmailAndPassword(email.toLowerCase(), password);
      const docSnapshot = await firestore.collection('user').where('uid', '==', results.user.uid).limit(1).get()
      if (docSnapshot.docs && docSnapshot.docs.length > 0) {
        const data = await docSnapshot.docs[0].data()
        if (data.status === 'active') {
          dispatch({
            type: LOGIN_SUCCESS,
            payload: {user: data},
          });
        } else {
          dispatch({
            type: LOGIN_FAILED,
            payload: {message: ''},
          });
          return Promise.reject('Your account is inactive. Contact support to activate this.')
        }
      } else {
        dispatch({
          type: LOGIN_FAILED,
          payload: {message: ''},
        });
        return Promise.reject('No user found')
      }

    } catch (e) {
      dispatch({
        type: LOGIN_FAILED,
        payload: {message: e},
      });
      return Promise.reject('wrong credentials')
    }
  };
};
export const logout = () => {
  return async (dispatch) => {
    dispatch({
      type: LOADING,
      payload: {loading: true},
    });
    try {
      await firebase.auth().signOut();
      dispatch({
        type: LOGOUT,
        payload: {loading: false},
      });
    } catch (e) {
      dispatch({
        type: LOADING,
        payload: {loading: false},
      });
      return Promise.reject(e)
    }
  };
};
export const createAccount = (user) => {
  return async (dispatch) => {
    dispatch({
      type: LOADING,
      payload: {loading: true},
    });
    try {
      let users = await firestore.collection('user').where('mobile', '==', `${user.mobile}`).get();
      if (users.docs.length > 0) {
        dispatch({
          type: LOGIN_FAILED,
          payload: {message: 'Error: Phone number already exists.'},
        });
        return Promise.reject('Error: Phone number already exists.')
      }
      users = await firestore.collection('user').where('email', '==', user.email).get();
      if (users.docs.length > 0) {
        dispatch({
          type: LOGIN_FAILED,
          payload: {message: 'Error: Email already exists'},
        });
        return Promise.reject('Error: Email already exists')
      }
      const results = await firebase.auth().createUserWithEmailAndPassword(user.email, user.password);
      user.uid = results.user.uid;
      await firestore.collection('user').add(user);
      dispatch({
        type: LOADING,
        payload: {loading: false},
      });
    } catch (e) {
      dispatch({
        type: LOGIN_FAILED,
        payload: {message: e},
      });
      return Promise.reject(e)
    }
  };
};


