import React, {useEffect, useState} from 'react';
import {Image, Linking, Platform, StyleSheet} from 'react-native';
import {useDispatch, useSelector} from 'react-redux'
import ViewPager from '@react-native-community/viewpager';

import {Text, View} from '../components/Themed';
import {heightPercentageToDP as hp, widthPercentageToDP as wp} from 'react-native-responsive-screen';
import Loading from "../components/Loading";
import {bannersList, fetchUser, ordersList} from './../containers/actions/app';
import {Button} from "react-native-elements";

export default function HomeScreen(props) {
  const isWeb = Platform.OS === 'web'
  const dispatch = useDispatch();
  const {orders, user, banners} = useSelector(state => state.app)
  let newOrder = 0, closedOrder = 0, processing = 0
  orders.forEach((order) => {
    if (order.status === 'new')
      newOrder++;
    else if (order.status === 'closed')
      closedOrder++;
    else if (order.status === 'processing')
      processing++;
  })
  useEffect(() => {
    dispatch(ordersList(user.uid))
    dispatch(fetchUser(user.email))
    dispatch(bannersList())
  }, [])
  useEffect(() => {
    if (banners.length > 0) {
      console.log(' banners ', banners)
    }
  }, [banners])
  const OrderView = ({type, title, color}) => {
    return (
      <View style={[styles.orderContainer, type !== 'new' ? {backgroundColor: color} : {}]}>
        <Text style={styles.orderTitle}>{title}</Text>
      </View>
    )
  }
  return (
    <View style={styles.container}>
      <Text style={styles.title}>Orders</Text>
      <View style={styles.orderView}>
        <OrderView type='new' title={`${newOrder}\nNew`} color={''}/>
        <OrderView type='processing' title={`${processing}\nProcessing`} color={'rgb(228,181,48)'}/>
        <OrderView type='closed' title={`${closedOrder}\nClosed`} color={'rgb(228,129,41)'}/>
      </View>
      <Button
        onPress={() => props.navigation.navigate('Search')}
        raised={false}
        titleStyle={{color: '#222831'}}
        containerStyle={{
          marginBottom: hp(2),
          marginTop: Platform.OS === 'web' ? hp(15) : hp(5),
          width: Platform.OS === 'web' ? '80%' : '70%',
          maxWidth: Platform.OS === 'web' ? 400 : 'auto'
        }}
        buttonStyle={{backgroundColor: '#ffd56b'}}
        title="Search"/>
      {!isWeb && <Text style={[styles.title, {marginTop: hp(4)}]}>Our Latest Collections</Text>}

      {/*{!isWeb &&
      <ViewPager style={{width: wp(90), height: hp(32)}} initialPage={0}>
        {
          banners.map((banner) => (
            <View style={styles.galleryContainer}>
              <Image
                style={{
                  marginTop: hp(1.5),
                  width: '92%', height: '82%'
                }}
                source={{uri: banner.image}}/>
              <Text
                onPress={() => {
                  if (banner.youtubeid) {
                    Linking
                      .openURL(`vnd.youtube://watch?v=${banner.youtubeid}`)
                      .catch(() => {

                      })
                  }
                }}
                style={[styles.collectionTitle]}>{banner.name}</Text>
            </View>
          ))}
      </ViewPager>
      }*/}
      <Loading/>
    </View>
  );
}

const styles = StyleSheet.create({
  galleryContainer: {
    width: '100%',
    height: '100%',
    borderWidth: 1,
    borderColor: 'lightgrey',
    alignItems:'center'
  },
  orderView: {
    flexDirection: 'row',
    marginTop: hp(3)
  },
  orderContainer: {
    backgroundColor: 'rgb(74,174,197)',
    width: Platform.OS === 'web' ? ((wp(100) > 1024 ? 1024 : wp(100)) / 4) : wp(30),
    height: Platform.OS === 'web' ? ((wp(100) > 1024 ? 1024 : wp(100)) / 4) : wp(30),
    borderRadius: wp(25),
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: wp(2),
    marginRight: wp(2)
  },
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: 20,
    backgroundColor: '#fff',
    minHeight: Platform.OS === 'web' ? hp(94) : 'auto'
  },
  title: {
    fontSize: 25,
    fontWeight: 'bold',
    alignSelf: 'flex-start',
    marginTop: hp(2),
    color: '#3b5360'
  },
  collectionTitle: {
    fontSize: 20,
    color: '#214151',
    textAlign:'center',
    marginTop: hp(1)
  },
  orderTitle: {
    fontSize: 20,
    fontWeight: 'bold',
    color: '#fff',
    textAlign: 'center'
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: '80%',
  },
});
