import React, {useEffect, useState} from "react";
import {widthPercentageToDP as wp, heightPercentageToDP as hp} from 'react-native-responsive-screen';
import styles from "./style";
import {
  Keyboard,
  Text,
  View,
  TextInput,
  TouchableWithoutFeedback,
  Alert,
  KeyboardAvoidingView,
  ActivityIndicator,
  Platform
} from 'react-native';
import {Button} from 'react-native-elements';
import Wallpapers from "../../components/Wallpapers";
import {useDispatch, useSelector} from "react-redux";
import {emailLogin, fetchUser} from "../../containers/actions/app";
import Loading from "../../components/Loading";

function LoginScreen(props) {
  console.log(props)
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const {authenticated, user, errorMessage} = useSelector( state => state.app )
  const isWeb = typeof document != 'undefined';
  if (authenticated){
    props.navigation.replace('Root')
  }
  const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }
  const validatePhoneNumber = (text) => {
    const phoneNo = /^\d{10}$/;
    return !!text.match(phoneNo);
  }
  const onLoginPress = () => {
    if (!validateEmail(email) && !validatePhoneNumber(email)) {
      alert('Enter a valid email address or phone number');
      return;
    }
    if (password.length < 6) {
      alert('Enter a valid pin');
      return;
    }
    dispatch(emailLogin(email,password)).then(()=>{},(error)=>{
      alert(error)
    })
  }
  return (
    <KeyboardAvoidingView style={styles.containerView} behavior="padding">
      <TouchableWithoutFeedback onPress={()=>{ if(isWeb) Keyboard.dismiss }}>
        <View style={styles.loginScreenContainer}>
          <View style={styles.loginFormView}>
            <Wallpapers/>
            <TextInput
              value={email}
              onChangeText={(text) => setEmail(text)}
              keyboardType={'email-address'}
              placeholder="Mobile Number/Email" placeholderColor="#c4c3cb"
              style={[styles.loginFormTextInput,{marginTop: hp(5)}]}/>
            <TextInput
              onChangeText={(text) => setPassword(text)}
              keyboardType={'number-pad'}
              placeholder="Pin"
              placeholderColor="#c4c3cb"
              style={styles.loginFormTextInput}
              secureTextEntry={true}/>
            <Button
              buttonStyle={{backgroundColor:'#e47729'}}
              containerStyle={styles.loginButton}
              onPress={() => onLoginPress()}
              title="Login"
            />
            <Button
              type={'clear'}
              containerStyle={styles.loginButton}
              onPress={() => props.navigation.navigate('ForgotPassword')}
              title="Forgot Password?"
            />
            <Button
              onPress={() => props.navigation.replace('Signup')}
              raised
              titleStyle={{color: '#222831'}}
              containerStyle={[styles.loginButton, {marginTop: hp(2)}]}
              buttonStyle={{ backgroundColor: '#ffd56b'}}
              title="Create account"/>
          </View>
          <Loading/>
        </View>
      </TouchableWithoutFeedback>
    </KeyboardAvoidingView>
  );
}
export default LoginScreen;
