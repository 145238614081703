import {Ionicons} from '@expo/vector-icons';
import {createBottomTabNavigator} from '@react-navigation/bottom-tabs';
import {createStackNavigator} from '@react-navigation/stack';
import React, {useEffect, useState} from 'react';
import {Platform, AppState} from "react-native";
import {useSelector, useDispatch} from 'react-redux';
import {listenForUpdates} from "./../containers/actions/app";

import Colors from '../constants/Colors';
import useColorScheme from '../hooks/useColorScheme';
import HomeScreen from '../screens/HomeScreen';
import SearchScreen from '../screens/SearchScreen';
import CartScreen from '../screens/CartScreen';
import ConfirmScreen from '../screens/ConfirmOrderScreen';
import OrdersScreen from '../screens/OrdersScreen';
import OrderPlacedScreen from '../screens/OrderPlacedScreen';
import OrderDetailScreen from '../screens/OrderDetail';
import ProfileScreen from '../screens/profile/profile';
import {
  BottomTabParamList,
  HomeParamList,
  SearchParamList,
  CartParamList,
  OrdersParamList,
  ProfileParamList
} from '../types';

const BottomTab = createBottomTabNavigator<BottomTabParamList>();

export default function BottomTabNavigator() {
  const [currentState, setCurrentState] = useState(AppState.currentState)
  const dispatch = useDispatch();
  const {user, lastSync} = useSelector(state => state.app)
  const _handleAppStateChange = nextAppState => {
    if (nextAppState === 'active') {
      console.log('App has come to the foreground!');
    }
    setCurrentState(nextAppState)
  };
  useEffect(()=>{
    dispatch(listenForUpdates(user.email, lastSync));
    AppState.addEventListener('change', _handleAppStateChange);
    return function cleanup() {
      AppState.removeEventListener('change', _handleAppStateChange);
    };
  },[])

  const colorScheme = useColorScheme();
  if (Platform.OS === 'web') {
    return (
      <BottomTab.Navigator
        initialRouteName="Home"
        tabBarOptions={{activeTintColor: Colors[colorScheme].tint}}>
        <BottomTab.Screen
          name="Home"
          component={HomeScreen}
          options={{
            tabBarIcon: ({color}) => <TabBarIcon name="home" color={color}/>,
          }}
        />
        <BottomTab.Screen
          name="Search"
          component={SearchScreen}
          options={{
            tabBarIcon: ({color}) => <TabBarIcon name="search" color={color}/>,
          }}
        />
        <BottomTab.Screen
          name="Orders"
          component={OrdersScreen}
          options={{
            tabBarIcon: ({color}) => <TabBarIcon name="list" color={color}/>,
          }}
        />
        <BottomTab.Screen
          name="Cart"
          component={CartScreen}
          options={{
            tabBarIcon: ({color}) => <TabBarIcon name="cart" color={color}/>,
          }}
        />
        <BottomTab.Screen
          name="Profile"
          component={ProfileScreen}
          options={{
            tabBarIcon: ({color}) => <TabBarIcon name="person-circle-outline" color={color}/>,
          }}
        />
      </BottomTab.Navigator>
    );
  }
  return (
    <BottomTab.Navigator
      initialRouteName="Home"
      tabBarOptions={{activeTintColor: Colors[colorScheme].tint}}>
      <BottomTab.Screen
        name="Home"
        component={HomeNavigator}
        options={{
          tabBarIcon: ({color}) => <TabBarIcon name="home" color={color}/>,
        }}
      />
      <BottomTab.Screen
        name="Search"
        component={SearchNavigator}
        options={{
          tabBarIcon: ({color}) => <TabBarIcon name="search" color={color}/>,
        }}
      />
      <BottomTab.Screen
        name="Orders"
        component={OrdersNavigator}
        options={{
          tabBarIcon: ({color}) => <TabBarIcon name="list" color={color}/>,
        }}
      />
      {
        user && user.permission === 'order' &&
        <BottomTab.Screen
          name="Cart"
          component={CartNavigator}
          options={{
            tabBarIcon: ({color}) => <TabBarIcon name="cart" color={color}/>,
          }}
        />
      }
      <BottomTab.Screen
        name="Profile"
        component={ProfileNavigator}
        options={{
          tabBarIcon: ({color}) => <TabBarIcon name="person-circle-outline" color={color}/>,
        }}
      />
    </BottomTab.Navigator>
  );
}

// You can explore the built-in icon families and icons on the web at:
// https://icons.expo.fyi/
function TabBarIcon(props: { name: string; color: string }) {
  return <Ionicons size={30} style={{marginBottom: -3}} {...props} />;
}

// Each tab has its own navigation stack, you can read more about this pattern here:
// https://reactnavigation.org/docs/tab-based-navigation#a-stack-navigator-for-each-tab
const HomeStack = createStackNavigator<HomeParamList>();

function HomeNavigator() {
  return (
    <HomeStack.Navigator>
      <HomeStack.Screen
        name="HomeScreen"
        component={HomeScreen}
        options={{
          headerTitle: 'Home',
          headerStyle: {
            backgroundColor: '#1894da',
          },
          headerTintColor: '#fff',
        }}
      />
    </HomeStack.Navigator>
  );
}

const SearchStack = createStackNavigator<SearchParamList>();

function SearchNavigator() {
  return (
    <SearchStack.Navigator>
      <SearchStack.Screen
        name="SearchScreen"
        component={SearchScreen}
        options={{
          headerTitle: 'Search',
          headerStyle: {
            backgroundColor: '#1894da',
          },
          headerTintColor: '#fff',
        }}
      />
    </SearchStack.Navigator>
  );
}

const OrdersStack = createStackNavigator<OrdersParamList>();

function OrdersNavigator() {
  return (
    <OrdersStack.Navigator
      screenOptions={{
        headerStyle: {
          backgroundColor: '#1894da',
        },
        headerTintColor: '#fff',
      }}>
      <OrdersStack.Screen
        name="OrdersScreen"
        component={OrdersScreen}
        options={{
          headerTitle: 'Orders',
        }}
      />
      <OrdersStack.Screen
        name="OrderDetailScreen"
        component={OrderDetailScreen}
        options={{
          headerTitle: 'Order Detail',
        }}
      />
    </OrdersStack.Navigator>
  );
}

const ProfileStack = createStackNavigator<ProfileParamList>();

function ProfileNavigator() {
  return (
    <ProfileStack.Navigator>
      <ProfileStack.Screen
        name="ProfileScreen"
        component={ProfileScreen}
        options={{
          headerTitle: 'Profile',
          headerStyle: {
            backgroundColor: '#1894da',
          },
          headerTintColor: '#fff',
        }}
      />
    </ProfileStack.Navigator>
  );
}

const CartStack = createStackNavigator<CartParamList>();

function CartNavigator() {
  return (
    <CartStack.Navigator
      initialRouteName={'CartScreen'}
      screenOptions={{
        headerStyle: {
          backgroundColor: '#1894da',
        },
        headerTintColor: '#fff',
      }}>
      <CartStack.Screen
        name="CartScreen"
        component={CartScreen}
        options={{
          headerTitle: 'Cart',
        }}
      />
      <CartStack.Screen
        name="ConfirmScreen"
        component={ConfirmScreen}
        options={{
          headerTitle: 'Confirm Order',
        }}
      />
      <CartStack.Screen
        name="OrderPlacedScreen"
        component={OrderPlacedScreen}
        options={{
          headerTitle: 'Order Placed',
        }}
      />
    </CartStack.Navigator>
  );
}