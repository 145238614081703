import React from 'react';
import firebase from 'firebase';
import 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyBPAl0bfMuxrAxmhB6v2HDhhqurqS5M_1c",
  authDomain: "wallking-hosting.firebaseapp.com",
  databaseURL: "https://wallking-hosting-default-rtdb.firebaseio.com",
  projectId: "wallking-hosting",
  storageBucket: "wallking-hosting.appspot.com",
  messagingSenderId: "582707423899",
  appId: "1:582707423899:web:a7e41581b6f6b0be7f743e"

/*  apiKey: "AIzaSyDgKbePq9wTBGSrYhpvsWObqYrd6QJxxQk",
  authDomain: "stockwall-local.firebaseapp.com",
  databaseURL: "https://stockwall-local-default-rtdb.firebaseio.com",
  projectId: "stockwall-local",
  storageBucket: "stockwall-local.appspot.com",
  messagingSenderId: "1086524123502",
  appId: "1:1086524123502:web:f6b8a49153c6fe3153397f"*/

};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

const firestore = firebase.firestore()
export {firebase, firestore};