import {StatusBar} from 'expo-status-bar';
import React from 'react';
import {SafeAreaProvider} from 'react-native-safe-area-context';
import {Platform, View} from "react-native";
import {Provider} from 'react-redux';
import {PersistGate} from 'redux-persist/integration/react';
import {widthPercentageToDP as wp, heightPercentageToDP as hp} from 'react-native-responsive-screen';
import {store, persistor} from './containers/store';
import useCachedResources from './hooks/useCachedResources';
import useColorScheme from './hooks/useColorScheme';
import Navigation from './navigation';


export default function App() {
  const isLoadingComplete = useCachedResources();
  const colorScheme = useColorScheme();

  if (!isLoadingComplete) {
    return null;
  } else {
    return (
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <SafeAreaProvider>
            <View
              style={
                Platform.OS === 'web' ? {
                  maxWidth: 1024,
                  width:'100%',
                  minHeight: hp(100),
                  justifyContent: 'center',
                  alignSelf: 'center',
                  backgroundColor: 'red'
                } : {flex: 1, backgroundColor: '#fff'}}>
              <Navigation colorScheme={colorScheme}/>
              <StatusBar style={'light'}/>
            </View>
          </SafeAreaProvider>
        </PersistGate>
      </Provider>
    );
  }
}
