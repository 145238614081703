import * as React from 'react';
import {Platform, StyleSheet, View} from 'react-native';
import Gallery from './../gallery/Gallery';
import {heightPercentageToDP as hp, widthPercentageToDP as wp} from 'react-native-responsive-screen';
import {Image} from "react-native-elements";

export default function Wallpapers() {
  const isWeb = typeof document != 'undefined';
  /*if(isWeb){
    return (<View/>)
  }*/
  return (
    <View style={styles.galleryContainer}>
      <Image
        containerStyle={Platform.OS === 'web' ? {width:300, height:100} : {width:'100%',height: '100%'}}
        source={require('./../assets/images/walpaper.png')}/>
    </View>
  );
}

const styles = StyleSheet.create({
  galleryContainer: {
    width: wp(90),
    height: Platform.OS === 'web' ? 'auto' : hp(13),
    marginBottom: Platform.OS === 'web' ? 50 : hp(5),
    borderWidth:0,
    borderColor: 'lightgrey',
    maxWidth: Platform.OS === 'web' ? 500 : 'auto',
    alignItems:'center'
  },
});
